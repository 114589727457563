
















































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Receptor, ReceptorDocumento } from '@/core/models/residuo'
import { ReceptorService } from '@/core/services/residuo'
import { ArquivoService } from '@/core/services/shared';
import { PageBase } from '@/core/models/shared';
import { TipoDocumento } from '@/core/models/geral';
import { TipoDocumentoService } from '@/core/services/geral';

@Component
export default class ListaDocumentoReceptor extends PageBase {
  search: any = '';
  loading: boolean = false;
  loadingDownload: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  lista: any[] = [];
  options: any = {
    itemsPerPage: 15
  };
  headers: any[] = [
    { text: '', value: 'actions',sortable: false },
    { text: 'Receptor', value: 'receptor.nome' },
    { text: 'Identificação', value: 'documento.identificacao' },
    { text: 'Descrição', value: 'documento.descricao' },
    { text: 'Tipo', value: 'documento.tipo.nome' },
    { text: 'Arquivo', value: 'documento.arquivo.nome' },
    { text: 'Validade', value: 'documento.dataValidade', type:'date' },
  ];
  sheet: boolean = false;
  filtro: any = {
    receptorId: null,
    identificacao: null,
    descricao: null,
    tipoId: null,
    protocoloValidade: null,
    validadeInicial: null,
    validadeFinal: null
  }

  tipos: TipoDocumento[] = [];
  receptores: Receptor[] = [];

  item = new ReceptorDocumento();
  service = new ReceptorService();

  @Watch('options', { deep: true })
  Atualizar(isFiltro: boolean = false){

    if(isFiltro == true){
      this.options.page = 1;
      this.sheet = false;
      isFiltro = false;
    }

    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.ListarTodosDocumentos(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.filtro, "Receptor").then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => {
        if (err.response.status == 403){
          this.$swal("Aviso", "Você não tem acesso a essa página!", "warning");
          this.$router.push({ name: 'home' });
        }
        else if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )
    .finally(() => (
        this.loading = false
    ));
  }

  mounted(){
    this.HeaderCliente()
    new TipoDocumentoService().ListarTudo().then(
      res => {
        this.tipos = res.data.items
      }
    ),
    this.service.ListarTudo().then(
      res => {
        this.receptores = res.data.items
      }
    )
  }

  HeaderCliente(){    
    if( !(this.app.role == 'Admin' || this.app.role == 'Arquivista' || this.app.role == 'ResiduoArquivista') ){
      this.headers.splice(0,1);
    }   
  }

  AbrirDialogCadastro(item?: ReceptorDocumento){
    if(item){
      this.service.ObterDocumentoPorId(item.id).then(
        res=>{
          this.item = new ReceptorDocumento(res.data)
          this.item.documento.dataValidade = this.item.documento.dataValidade? this.item.documento.dataValidade.toDateYYYYMMDD() : '';
          this.dialogCadastro = true;
        },
        err => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
    }
    else{
      this.item = new ReceptorDocumento();
      this.item.documento.empresaId = this.app.empresaId;
      this.dialogCadastro = true;
    }
  }

  Excluir(item: ReceptorDocumento){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.DeletarDocumento(item.id).then(res => {
          if (res.status == 200){
            return res.data;
          }
        },
        err => {
          if (err.response.status == 403){
            this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
          }
          else{
            this.$swal('Aviso', err.response.data, 'error')
          }
        })
      },
    // @ts-ignore: Unreachable code error
    allowOutsideClick: () => !this.$swal.isLoading()
    })
    .then((result) => {
        if(result.value) {
            this.$swal("Aviso", result.value, "success");
            this.Atualizar();
        }
    })
  }

  Download(item: ReceptorDocumento) {
    this.loadingDownload = true;
    this.service.DownloadDocumento(item.id).then(
      async (res) => {

        if (res.status == 204){
          return;
        }

        const arquivoService = new ArquivoService();
        await arquivoService.Download(res.data, res.headers['content-type'], item.documento.arquivo!.nome);

      },
      (err) => this.$swal("Aviso", err.message, "error")
    ).finally(() => {
      this.loadingDownload = false;
    });
  }
}

